// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-tsx": () => import("/opt/build/repo/src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-painting-tsx": () => import("/opt/build/repo/src/templates/Painting.tsx" /* webpackChunkName: "component---src-templates-painting-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-tsx": () => import("/opt/build/repo/src/pages/a-propos.tsx" /* webpackChunkName: "component---src-pages-a-propos-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

